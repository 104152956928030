import React, { Component } from 'react'
import Footer from '../../components/footer/index'
import AppBar from '../../components/appbar/index'
import { Box, Typography, Container } from '@mui/material'
import WhatsAppModal from '../../components/WhatsAppModal/WhatsAppModal'

export default class PrivacyPage extends Component {
  constructor() {
    super()
    this.state = {
      showWhatsAppModal: false,
    }

    this.toggleWhatsAppModal = this.toggleWhatsAppModal.bind(this)
  }

  toggleWhatsAppModal() {
    this.setState((prevState) => ({
      showWhatsAppModal: !prevState.showWhatsAppModal,
    }))
  }
  render() {
    const privacyPolicyContent = (
      <Box sx={{ padding: 3 }}>
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          style={{
            textDecorationLine: 'underline',
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Introduction
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          IntelliglanceTech (previously TechGlance) respects your privacy and is
          committed to protecting your personal data. This Privacy Policy
          explains how we collect, use, and share information about you when you
          use our software and services ("Services").
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Information We Collect
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Personal Information:</strong> We may collect personal
          information that you provide to us, such as your name, email address,
          phone number, and payment information.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Usage Information:</strong> We may collect information about
          your use of our Services, including IP address, browser type, device
          information, and usage data.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Cookies and Tracking Technologies:</strong> We use cookies and
          similar tracking technologies to collect and store information when
          you use our Services.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          How We Use Your Information
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>To Provide and Improve Our Services:</strong> We use your
          information to provide, maintain, and improve our Services.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>To Communicate with You:</strong> We use your information to
          send you updates, newsletters, and other communications related to our
          Services.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>To Process Payments:</strong> We use your payment information
          to process transactions.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>To Ensure Security:</strong> We use your information to
          detect, prevent, and respond to fraud, abuse, and security issues.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Sharing Your Information
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who perform services on our behalf, such
          as payment processing and data analysis.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Legal Requirements:</strong> We may disclose your information
          if required to do so by law or in response to a valid request from a
          governmental authority.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Business Transfers:</strong> In the event of a merger,
          acquisition, or sale of assets, your information may be transferred to
          the new owner.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Your Rights
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Access and Correction:</strong> You have the right to access
          and correct your personal information that we hold.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Data Portability:</strong> You have the right to request a
          copy of your personal information in a structured, commonly used, and
          machine-readable format.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Deletion:</strong> You have the right to request the deletion
          of your personal information, subject to certain exceptions.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Opt-Out:</strong> You have the right to opt-out of receiving
          marketing communications from us.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Data Security
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no internet
          transmission is completely secure, and we cannot guarantee the
          security of your information.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Changes to This Privacy Policy
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our website.
          Your continued use of our Services after any such changes constitutes
          your acceptance of the new Privacy Policy.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Contact Us
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          If you have any questions about this Privacy Policy, please contact us
          at support@intelliglancetech.com
        </Typography>
      </Box>
    )

    return (
      <div>
        <AppBar location="external" />
        <Container
          maxWidth="lg"
          sx={{ marginTop: 20, marginBottom: 4 }}
        >
          {privacyPolicyContent}
        </Container>
        <WhatsAppModal
          showWhatsAppModal={this.state.showWhatsAppModal}
          toggleWhatsAppModal={this.toggleWhatsAppModal}
        />
        <Footer />
      </div>
    )
  }
}
