import React from 'react'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import { Fancybox } from '@fancyapps/ui'
import logo from './assets/images/Intelliglance.png'

const ProgressItems = ({ item }) => {
  const {
    category,
    image,
    title,
    description,
    gallery,
    moreDetails,
    features,
    featuresStyle,
    status,
    logo: showLogo, // renamed to avoid conflict with imported logo
    featuresShow,
  } = item

  const openGallery = () => {
    Fancybox.show(
      gallery.map((img) => ({ src: require(`${img}`), type: 'image' }))
    )
  }

  const extractStyles = (stylesArray) => {
    const styles = {}
    stylesArray.forEach((style) => {
      const [key, ...valueParts] = style.split(' ')
      let value = valueParts.join(' ') // Join the rest of the array to support values with spaces

      // Append 'px' to numeric values for certain keys if units are not provided
      if (['bottom', 'top', 'left', 'right'].includes(key) && !isNaN(value)) {
        value += 'px'
      }

      if (key === 'flex-dir') {
        styles.flexDirection = value
      } else if (key === 'font-size') {
        styles.fontSize = value
      } else if (key === 'bg-color') {
        styles.backgroundColor = value
      } else if (key === 'font-color') {
        styles.color = value
      } else {
        styles[key] = value
      }
    })
    return styles
  }

  const featuresPortStyles = extractStyles(featuresStyle)
  const featureItemStyles = {
    fontSize: featuresPortStyles.fontSize || '10px',
    backgroundColor: featuresPortStyles.backgroundColor || 'rgba(0, 0, 0, 0.7)',
    color: featuresPortStyles.color || '#fff',
  }

  // Remove these specific styles from featuresPortStyles to avoid applying them to the container
  delete featuresPortStyles.fontSize
  delete featuresPortStyles.backgroundColor
  delete featuresPortStyles.color

  return (
    <div className={`portfolio-item ${category}`}>
      <div className="ribbon-wrapper">
        <div className="ribbon-content">
          <span
            className={`ribbon ${
              status === 'completed' ? 'completed' : 'ongoing'
            }`}
          >
            {status === 'completed' ? 'COMPLETED' : 'ONGOING'}
          </span>
        </div>
      </div>
      {featuresShow !== 'no' && (
        <div
          className="featuresPort"
          style={featuresPortStyles}
        >
          {features.map((feature, index) => (
            <div
              key={index}
              className="featurePort-item"
              style={featureItemStyles}
            >
              {feature}
            </div>
          ))}
        </div>
      )}
      {showLogo !== 'no' && (
        <img
          src={logo}
          alt="Company Logo"
          className="portfolio-logo"
        />
      )}
      <img
        src={require(`${image}`)}
        alt={title}
      />
      <div className="portfolio-hover">
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="linksContain">
          <button
            className="portfolioLinks"
            onClick={openGallery}
          >
            <i className="bx bx-show"></i>
          </button>
          {moreDetails && (
            <a
              className="portfolioLinks"
              href={moreDetails}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bx bx-link"></i>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProgressItems
