/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import swal from 'sweetalert'

export default function ContactSection() {
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('contact') // State to manage active tab
  const form = useRef()

  function handleChange(key = '', value = '') {
    if (key) {
      setState({
        ...state,
        [key]: value,
      })
    }
  }

  function validateForm() {
    let formErrors = {}
    if (!state.name) formErrors.name = true
    if (!state.email) formErrors.email = true
    if (!state.subject) formErrors.subject = true
    if (!state.message) formErrors.message = true
    return formErrors
  }

  function sendEmail(e) {
    e.preventDefault()

    const formErrors = validateForm()
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      swal(
        'Invalid information',
        'Please provide name, email, subject, and a message.',
        'error'
      )
      setTimeout(() => {
        setErrors({})
      }, 5000)
    } else {
      setLoading(true)
      emailjs
        .sendForm(
          'service_jqe9pqu',
          'template_kbxkjs1',
          form.current,
          'WImraXvK-CfhtukCH'
        )
        .then(
          (result) => {
            swal(
              'Request Sent',
              'We have received your request and will respond within 24 hours',
              'success'
            )
            setState({
              name: '',
              email: '',
              subject: '',
              message: '',
            })
            form.current.reset()
            setLoading(false)
          },
          (error) => {
            swal('Oops!', 'Something went wrong', 'error')
            setLoading(false)
          }
        )
    }
  }

  return (
    <section
      className="contact"
      id="contact"
    >
      <div className="container">
        <div
          className="section-title"
          data-aos="fade-up"
        >
          <i
            style={{ color: '#60BB44', fontSize: 40 }}
            className="ri-hand-heart-line"
          ></i>
          <h4>
            More Questions? <br />
            Need expert assistance? <br />
            Want to get in touch for a personalized experience?
          </h4>
          <p>
            Don't worry, we got you all covered.{' '}
            <span
              role="img"
              aria-label="smiling face"
            >
              😄
            </span>{' '}
            Just drop a query and we'll get back to you in no time.
          </p>
        </div>
        <div
          className="section-title"
          data-aos="fade-up"
        >
          <h2>Contact Us</h2>
        </div>

        <div className="tab-content">
          {activeTab === 'contact' && (
            <div className="row contact-content">
              <div
                className="col-lg-4 col-md-6 "
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="contact-about">
                  <h3>IntelliglanceTech</h3>
                  <p>
                    We are a team of talented individuals transforming ideas to
                    reality{' '}
                    <span
                      role="img"
                      aria-label="cool emoji"
                    >
                      😎
                    </span>
                  </p>
                  <div className="social-links">
                    <a
                      href="https://api.whatsapp.com/send?phone=971568164532"
                      className="whatsapp"
                    >
                      <i className="icofont-whatsapp"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/intelliglance-tech-89b851321"
                      className="linkedin"
                    >
                      <i className="icofont-linkedin"></i>
                    </a>
                    <a
                      href="https://join.skype.com/invite/usxDfoHZdUZ4"
                      className="skype"
                    >
                      <i className="icofont-skype"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="info">
                <div>
                  <i className="ri-mail-send-line"></i>
                  <p>
                    <a
                      style={{
                        color: 'black',
                      }}
                      href="mailto:support@intelliglancetech.com"
                    >
                      support@intelliglancetech.com
                    </a>
                  </p>
                </div>
                {/* <div>
                  <i className="ri-mail-send-line"></i>
                  <p>
                    anam@intelliglancetech.com
                    <br />
                    (Customer relations expert)
                  </p>
                </div> */}
                <div>
                  <i className="ri-phone-line"></i>
                  <p>
                    {' '}
                    <a
                      style={{
                        color: 'black',
                      }}
                      href="tel:+15852450314"
                    >
                      +1 585 245 0314{' '}
                    </a>
                  </p>
                </div>
                <div>
                  <i className="ri-phone-line"></i>
                  <p>
                    {' '}
                    <a
                      style={{
                        color: 'black',
                      }}
                      href="tel:+971568164532"
                    >
                      +971-568164532{' '}
                    </a>
                  </p>
                </div>
                <div>
                  <i className="ri-phone-line"></i>
                  <p>
                    {' '}
                    <a
                      style={{
                        color: 'black',
                      }}
                      href="tel:+923122025939"
                    >
                      +92-3122025939
                    </a>{' '}
                  </p>
                </div>
                {/* <div>
                  <i className="ri-phone-line"></i>
                  <p>+92-3131036141</p>
                </div> */}
              </div>
              <div id="TestDIV"></div>
              <div
                className="col-lg-5 col-md-12"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <form
                  ref={form}
                  className="php-email-form"
                  onSubmit={sendEmail}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      required={true}
                      className="form-control"
                      id="name"
                      placeholder="Full Name"
                      style={{ borderColor: errors.name ? 'red' : '' }}
                      onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      required={true}
                      id="email"
                      placeholder="Email"
                      style={{ borderColor: errors.email ? 'red' : '' }}
                      onChange={(e) => handleChange('email', e.target.value)}
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      required={true}
                      id="subject"
                      placeholder="Subject"
                      style={{ borderColor: errors.subject ? 'red' : '' }}
                      onChange={(e) => handleChange('subject', e.target.value)}
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      required={true}
                      rows="5"
                      placeholder="Message"
                      style={{ borderColor: errors.message ? 'red' : '' }}
                      onChange={(e) => handleChange('message', e.target.value)}
                    ></textarea>
                    <div className="validate"></div>
                  </div>
                  <div className="mb-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={loading}
                      className="formButton"
                    >
                      {loading ? (
                        <div className="loader"></div>
                      ) : (
                        'Send Message'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
