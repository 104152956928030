import React from 'react'
import googleLogo from './assets/google.png'
import isoLogo from './assets/iso.png'
import microsoftLogo from './assets/microsoft.png'
import awsLogo from './assets/aws.png'
import jiraLogo from './assets/jira.png'

const certificationsData = [
  {
    logo: googleLogo,
    title: 'Google Partner Certification',
    issuedDate: 'January 2023',
  },
  {
    logo: isoLogo,
    title: 'ISO 9001:2015 Certification',
    issuedDate: 'March 2022',
  },
  {
    logo: microsoftLogo,
    title: 'Microsoft Certified Partner',
    issuedDate: 'December 2021',
  },
  {
    logo: awsLogo,
    title: 'AWS Certified Solutions Architect',
    issuedDate: 'February 2023',
  },
  {
    logo: jiraLogo,
    title: 'Atlassian Certified Jira Project Administrator',
    issuedDate: 'June 2023',
  },
]

const Certifications = () => {
  return (
    <section id="certifications">
      <div class="container">
        <div
          className="section-title"
          data-aos="fade-up"
        >
          <h2>Our Certifications</h2>
        </div>
        <div className="certifications-container">
          {certificationsData.map((cert, index) => (
            <div
              className="certification"
              key={index}
            >
              <img
                src={cert.logo}
                alt={`${cert.title} Logo`}
              />
              <h3>{cert.title}</h3>
              <p>
                <strong>Date Issued:</strong> {cert.issuedDate}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Certifications
