// import React from 'react'
// import '@fancyapps/ui/dist/fancybox/fancybox.css'
// import { Fancybox } from '@fancyapps/ui'
// import ProgressBar from '@ramonak/react-progress-bar'

// // Create a mapping of technology images
// const techImages = {
//   './assets/images/tech/react.png': require('./assets/images/tech/react.png'),
//   './assets/images/tech/django.png': require('./assets/images/tech/django.png'),
//   './assets/images/tech/python.png': require('./assets/images/tech/python.png'),
//   './assets/images/tech/next.png': require('./assets/images/tech/next.png'),
//   './assets/images/tech/tail.png': require('./assets/images/tech/tail.png'),
//   './assets/images/tech/jira.png': require('./assets/images/tech/jira.png'),
//   './assets/images/tech/gitlab.png': require('./assets/images/tech/gitlab.png'),
//   './assets/images/tech/nodejs.png': require('./assets/images/tech/nodejs.png'),
//   './assets/images/tech/github.png': require('./assets/images/tech/github.png'),
//   './assets/images/tech/reactNative.png': require('./assets/images/tech/reactNative.png'),
//   './assets/images/tech/chakraUI.png': require('./assets/images/tech/chakraUI.png'),
//   './assets/images/tech/bootstrap.png': require('./assets/images/tech/bootstrap.png'),
// }

// const ProgressItems = ({ item }) => {
//   const {
//     category,
//     image,
//     title,
//     description,
//     gallery,
//     moreDetails,
//     features,
//     featuresStyle,
//     progress,
//     technologies,
//   } = item

//   const openGallery = () => {
//     console.log(gallery)

//     Fancybox.show(
//       gallery.map((img) => ({ src: require(`${img}`), type: 'image' }))
//     )
//   }

//   const extractStyles = (stylesArray) => {
//     const styles = {}
//     stylesArray.forEach((style) => {
//       const [key, ...valueParts] = style.split(' ')
//       let value = valueParts.join(' ') // Join the rest of the array to support values with spaces

//       // Append 'px' to numeric values for certain keys if units are not provided
//       if (['bottom', 'top', 'left', 'right'].includes(key) && !isNaN(value)) {
//         value += 'px'
//       }

//       if (key === 'flex-dir') {
//         styles.flexDirection = value
//       } else if (key === 'font-size') {
//         styles.fontSize = value
//       } else if (key === 'bg-color') {
//         styles.backgroundColor = value
//       } else if (key === 'font-color') {
//         styles.color = value
//       } else {
//         styles[key] = value
//       }
//     })
//     return styles
//   }

//   const featuresPortStyles = extractStyles(featuresStyle)
//   const featureItemStyles = {
//     fontSize: featuresPortStyles.fontSize || '10px',
//     backgroundColor: featuresPortStyles.backgroundColor || 'rgba(0, 0, 0, 0.7)',
//     color: featuresPortStyles.color || '#fff',
//   }

//   // Remove these specific styles from featuresPortStyles to avoid applying them to the container
//   delete featuresPortStyles.fontSize
//   delete featuresPortStyles.backgroundColor
//   delete featuresPortStyles.color

//   const progressColors = {
//     'Backlog Refinement': '#60bb44', // green
//     'Sprint Planning and Development': '#3498db', // blue
//     'Testing and Quality Assurance (QA)': '#e74c3c', // red
//     'Release Candidate (RC) and Beta Testing': '#9b59b6', // purple
//     'Production Release': '#e67e22', // orange
//   }

//   return (
//     <div className="progress-item-container">
//       <div className={`progress-item ${category}`}>
//         <div
//           className="featuresPort"
//           style={featuresPortStyles}
//         >
//           {features.map((feature, index) => (
//             <div
//               key={index}
//               className="featurePort-item"
//               style={featureItemStyles}
//             >
//               {feature}
//             </div>
//           ))}
//         </div>
//         <img
//           src={require(`${image}`)}
//           alt={title}
//         />
//         <div className="progress-hover">
//           <h3>{title}</h3>
//           <p>{description}</p>
//           <div className="linksContain">
//             <button
//               className="progressLinks"
//               onClick={openGallery}
//             >
//               <i className="bx bx-show"></i>
//             </button>
//             {moreDetails && (
//               <a
//                 className="progressLinks"
//                 href={moreDetails}
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <i className="bx bx-link"></i>
//               </a>
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="progress-details-container">
//         <div className="progress-details">
//           <h4>Current Status</h4>
//           {progress.map((item, index) =>
//             index % 2 === 0 ? (
//               <div
//                 key={index}
//                 style={{ marginBottom: '10px', width: '100%' }}
//               >
//                 <strong>{item}:</strong>
//                 <ProgressBar
//                   completed={parseInt(progress[index + 1])}
//                   bgColor={progressColors[item]}
//                   height="10px"
//                   labelAlignment="outside"
//                   labelColor="#000"
//                   baseBgColor="#d3d3d3"
//                 />
//               </div>
//             ) : null
//           )}
//         </div>
//         <div
//           className="progress-details-2"
//           style={{ paddingLeft: '10px' }}
//         >
//           <h4>Technologies Utilized</h4>
//           <div className="row clients techstack-Progress">
//             {technologies.map((tech, index) => (
//               <div
//                 key={index}
//                 className="col-lg-2 col-md-4 col-6"
//                 data-aos="zoom-in"
//                 data-aos-delay={(index + 1) * 100}
//               >
//                 <img
//                   src={techImages[tech]}
//                   className="img-fluid"
//                   alt=""
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default ProgressItems

import React from 'react'

const ProgressItems = () => {
  return <div></div>
}

export default ProgressItems
