import React, { Component } from 'react'
export default class ClientsSection extends Component {
  render() {
    return (
      <section id="clients">
        <div class="container">
          <div
            className="section-title"
            data-aos="fade-up"
          >
            <h2>Our Clients</h2>
          </div>
          <div class="row clients">
            {/* MOBILE APP CLIENTS */}

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/mobile/q8rpm/q8rpm.png')}
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="500"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/mobile/easypdf/easypdfAlt.png')}
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/mobile/speedtest/speedtest.png')}
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="100"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/mobile/mathup/mathup.png')}
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="200"
              />
            </div>

            {/* WEB CLIENTS */}

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/web/adakas/adakas.png')}
                class="img-fluid"
                alt="adakas"
                data-aos="zoom-in"
                data-aos-delay="300"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/web/iibso/iibso.png')}
                class="img-fluid"
                alt="iibso"
                data-aos="zoom-in"
                data-aos-delay="300"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/web/alma/alma.png')}
                class="img-fluid"
                alt="alma"
                data-aos="zoom-in"
                data-aos-delay="300"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/web/workhall/workhallAlt.png')}
                class="img-fluid"
                alt="workhall"
                data-aos="zoom-in"
                data-aos-delay="400"
              />
            </div>
            <div class="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/web/iconivo/iconivo.png')}
                class="img-fluid"
                alt="iconivo"
                data-aos="zoom-in"
                data-aos-delay="400"
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
