import React, { Component } from 'react'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default class Footer extends Component {
  state = {
    showModal: false,
    modalContent: '',
  }

  handleShow = (content) => {
    this.setState({ showModal: true, modalContent: content })
  }

  handleClose = () => {
    this.setState({ showModal: false, modalContent: '' })
  }

  render() {
    return (
      <>
        <div className="office-contacts">
          <h1 className="office-contacts-title">Our Offices</h1>
          <div className="offices">
            <div className="office">
              <h3>Wyoming, USA</h3>
              <p>
                <span>312 W 2nd St</span>
                <span>Casper, WY 82601</span>
                <span>Unit #A4383</span>
                <span>
                  <a href="tel:+15852450314">+1 585 245 0314</a>
                </span>
              </p>
            </div>
            <div className="office">
              <h3>Karachi, Pakistan</h3>
              <p>
                <span>Gulshan-e-Iqbal,</span>
                <span>block 13 D</span>
                <span>Karachi, 75300</span>
                <span>
                  <a href="tel:+923131036141">+92-3131036141</a>
                </span>
              </p>
            </div>
            <div className="office">
              <h3>Dubai, UAE</h3>
              <p>
                <a href="tel:+971568164532">+971-568164532</a>
              </p>
            </div>
          </div>
          <div className="contact-info">
            <div className="contact-item">
              <i className="ri-mail-send-line"></i>
              <a href="mailto:support@intelliglancetech.com">
                support@intelliglancetech.com
              </a>
            </div>
            <div className="contact-item">
              <i className="ri-phone-line"></i>
              <a href="tel:+15852450314">+1- 5852450314</a>
            </div>
            <div className="contact-item">
              <i className="ri-phone-line"></i>
              <a href="tel:+971568164532">+971-568164532</a>
            </div>
            <div className="contact-item">
              <i className="ri-phone-line"></i>
              <a href="tel:+923122025939">+92-3122025939</a>
            </div>
          </div>
        </div>

        <footer
          id="footer"
          className="footer"
        >
          <div className="footer-container">
            <nav className="footer-links text-lg-left text-center pt-2 pt-lg-0">
              <a href="/privacy_policy">Privacy Policy</a>
              <a href="/terms_conditions">Terms of Use</a>
            </nav>
            <div className="footer-credits">
              <div className="credits">
                Designed with ❤️ @ IntelliglanceTech
              </div>
              <div className="copyright">
                &copy; Copyright <strong>IntelliglanceTech</strong>. All Rights
                Reserved
              </div>
            </div>
            <div className="social-links">
              <a
                href="https://api.whatsapp.com/send?phone=971568164532"
                className="whatsapp"
              >
                <i className="icofont-whatsapp"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/intelliglance-tech-89b851321"
                className="linkedin"
              >
                <i className="icofont-linkedin"></i>
              </a>
              <a
                href="https://join.skype.com/invite/usxDfoHZdUZ4"
                className="skype"
              >
                <i className="icofont-skype"></i>
              </a>
            </div>
          </div>
        </footer>
      </>
    )
  }
}
