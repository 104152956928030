import React from 'react';
import Navigation from './configs/routing/router'

function App() {
  return (
    <Navigation/>
  );
}

export default App;
