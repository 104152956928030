import React, { Component } from 'react'
import { Collapse } from 'react-collapse'
import { FaChevronDown, FaChevronUp, FaInfoCircle } from 'react-icons/fa'
import './faq.css'

export default class FAQSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: null,
    }
  }

  handleToggle = (index) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index,
    }))
  }

  render() {
    const { activeIndex } = this.state
    return (
      <section
        id="faq"
        className="faq"
      >
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
          >
            <h2>Frequently Asked Questions</h2>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="faq-heading"
          >
            Design & Animation
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(0)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>
                Consider the possibility that I need a design for a website, how
                is this organised?
              </h4>
              {activeIndex === 0 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 0}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  We start by understanding your target audience and goals. We
                  create a strategic concept and UX framework, incorporating
                  your feedback. After finalizing the design, we develop the
                  platform/software content.
                </p>
              </div>
            </Collapse>
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(1)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>Would you be able to give us examples of your work?</h4>
              {activeIndex === 1 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 1}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  We have excellent experience with our customers.
                  <br />
                  We design websites, create user-friendly applications, and
                  make animations to enhance your site or app's conversion rate.
                  <br />
                  For more information, contact our representative to view our
                  portfolio.
                </p>
              </div>
            </Collapse>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="faq-heading"
          >
            Feasibility & Prototyping
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(2)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>
                Would you be able to test the specialized feasibility of my
                ideas?
              </h4>
              {activeIndex === 2 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 2}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  We are often asked if we share the costs of creating a
                  product. We require the entrepreneur's commitment and
                  willingness to take responsibility and risk for their idea or
                  application. If these conditions are met, Intelliglancetech
                  may choose to invest in an appealing concept.
                </p>
              </div>
            </Collapse>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="faq-heading"
          >
            Projects
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(3)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>
                Could you likewise give the plan and design to my site or
                application?
              </h4>
              {activeIndex === 3 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 3}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  At Intelliglancetech, we specialize in software development,
                  including UX and UI expertise. If you need a Designer, we can
                  provide UX or UI Designers for turnkey projects from our
                  extensive network of specialists.
                </p>
              </div>
            </Collapse>
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(4)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>
                How would I arrange the management when my software is prepared?
              </h4>
              {activeIndex === 4 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 4}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  For larger projects, we recommend clients manage and test
                  in-house, with Intelliglancetech providing expert support. For
                  smaller projects, we offer post-delivery management to ensure
                  ongoing support.
                </p>
              </div>
            </Collapse>
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(5)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>
                Would you be able to assume control over the support of a
                current application?
              </h4>
              {activeIndex === 5 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 5}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  Yes! We help clients manage existing applications for various
                  reasons, such as:{' '}
                  <ul>
                    <li>The original developers are no longer available.</li>
                    <li>The provider has stopped support.</li>
                    <li>Lack of application documentation.</li>
                    <li>Outdated technology needing an upgrade or rebuild.</li>
                    <li>High maintenance costs.</li>
                    <li>The application no longer meets requirements.</li>
                  </ul>
                  We discuss your support needs and offer solutions, such as:
                  <ul>
                    <li>
                      Setting up a maintenance team for daily support and
                      redevelopment.
                    </li>
                    <li>
                      Implementing projects to update and improve the
                      application, ensuring long-term continuity and good
                      documentation.
                    </li>
                  </ul>
                </p>
              </div>
            </Collapse>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="faq-heading"
          >
            Security
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(6)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>What arrangements do you make to work securely?</h4>
              {activeIndex === 6 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 6}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  A secure workplace is crucial for clients and experts. We
                  enforce strict safety rules, including non-disclosure
                  agreements for all employees and interns. We work primarily
                  from our office and know our employees personally. Security
                  needs vary by client, so we customize remote development teams
                  and can take additional measures as needed.
                </p>
              </div>
            </Collapse>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="faq-heading"
          >
            Quality and Testing
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(7)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>
                Can you additionally offer quality control as a different
                assistance?
              </h4>
              {activeIndex === 7 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 7}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  Intelliglancetech provides quality assurance experts and
                  testers as part of your development process. We can perform
                  specific tests on demand and help resolve quality issues,
                  starting with understanding your needs.
                </p>
              </div>
            </Collapse>
          </div>

          <div
            className="faq-item"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div
              className="faq-question"
              onClick={() => this.handleToggle(8)}
            >
              <FaInfoCircle className="info-icon" />
              <h4>Can you co-operate with an external QA-service?</h4>
              {activeIndex === 8 ? (
                <FaChevronUp className="arrow-icon" />
              ) : (
                <FaChevronDown className="arrow-icon" />
              )}
            </div>
            <Collapse
              isOpened={activeIndex === 8}
              springConfig={{ stiffness: 150, damping: 20 }}
            >
              <div className="faq-answer">
                <p>
                  There are several ways to involve external testers in the
                  development process: during software acceptance, as part of
                  the development team from the start, or a combination of both.
                  We tailor quality assurance to your needs and have experience
                  working with external QA experts, collaborating with your
                  specialists as required.
                </p>
              </div>
            </Collapse>
          </div>
        </div>
      </section>
    )
  }
}
