import React, { Component } from 'react'
import Footer from '../../components/footer/index'
import AppBar from '../../components/appbar/index'
import { Box, Typography, Container } from '@mui/material'
import WhatsAppModal from '../../components/WhatsAppModal/WhatsAppModal'

export default class TermsConditionsPage extends Component {
  constructor() {
    super()
    this.state = {
      showWhatsAppModal: false,
    }

    this.toggleWhatsAppModal = this.toggleWhatsAppModal.bind(this)
  }

  toggleWhatsAppModal() {
    this.setState((prevState) => ({
      showWhatsAppModal: !prevState.showWhatsAppModal,
    }))
  }
  render() {
    const termsOfUseContent = (
      <Box sx={{ padding: 3 }}>
        <Typography
          variant="h4"
          gutterBottom
          wrap
          align="center"
          style={{
            textDecorationLine: 'underline',
          }}
        >
          Terms & Conditions
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Introduction
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          Welcome to IntelliglanceTech (previously TechGlance). These Terms and
          Conditions ("Terms") govern your use of our software and services
          ("Services"). By using our Services, you agree to comply with and be
          bound by these Terms. If you do not agree to these Terms, please do
          not use our Services.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Changes to Terms
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          IntelliglanceTech reserves the right to modify these Terms at any
          time. We will notify you of any changes by posting the new Terms on
          our website. Your continued use of the Services after any such changes
          constitutes your acceptance of the new Terms.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Use of Services
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Eligibility:</strong> You must be at least 18 years old to use
          our Services.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Account:</strong> You may need to create an account to access
          some of our Services. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          <strong>Prohibited Activities:</strong> You agree not to engage in any
          activity that is illegal, harmful, or violates the rights of others,
          including but not limited to:
        </Typography>
        <ul>
          <Typography
            component="li"
            variant="body1"
          >
            Hacking or interfering with the operation of our Services.
          </Typography>
          <Typography
            component="li"
            variant="body1"
          >
            Using our Services for fraudulent purposes.
          </Typography>
          <Typography
            component="li"
            variant="body1"
          >
            Uploading or distributing any harmful or malicious content.
          </Typography>
        </ul>
        <Typography
          variant="h5"
          gutterBottom
        >
          Intellectual Property
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          All content and materials available on our Services, including but not
          limited to software, text, graphics, and logos, are the property of
          IntelliglanceTech or its licensors and are protected by intellectual
          property laws. You agree not to reproduce, distribute, or create
          derivative works from any content without our prior written
          permission.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Limitation of Liability
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          IntelliglanceTech will not be liable for any direct, indirect,
          incidental, special, or consequential damages arising from your use of
          our Services, even if we have been advised of the possibility of such
          damages.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Indemnification
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          You agree to indemnify and hold IntelliglanceTech harmless from any
          claims, losses, damages, liabilities, including legal fees, arising
          out of your use or misuse of our Services, your violation of these
          Terms, or your violation of any rights of another.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Governing Law
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          These Terms shall be governed by and construed in accordance with the
          laws of the jurisdiction in which IntelliglanceTech operates, without
          regard to its conflict of law principles.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
        >
          Contact Us
        </Typography>
        <Typography
          variant="body1"
          paragraph
        >
          If you have any questions about these Terms, please contact us at
          support@intelliglancetech.com.
        </Typography>
      </Box>
    )

    return (
      <div>
        <AppBar location="external" />
        <Container
          maxWidth="lg"
          sx={{ marginTop: 20, marginBottom: 4 }}
        >
          {termsOfUseContent}
        </Container>
        <WhatsAppModal
          showWhatsAppModal={this.state.showWhatsAppModal}
          toggleWhatsAppModal={this.toggleWhatsAppModal}
        />
        <Footer />
      </div>
    )
  }
}
