import React, { Component } from 'react'
export default class ArchitechtureSection extends Component {
  render() {
    return (
      <section
        id="features"
        class="features"
      >
        <div
          class="container"
          style={{ padding: 40 }}
        >
          <div
            class="section-title"
            data-aos="fade-up"
          >
            <h2> Principle Followed Architectures </h2>
            {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem</p> */}
          </div>

          <div
            class="row features"
            style={{ flexWrap: 'wrap' }}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Layered pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Client-server pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Master-slave pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
            <div
              class="icon-box"
              style={{ marginTop: 20, marginRight: 10, padding: 10 }}
            >
              <h3>
                <a href="/#">Pipe-filter pattern</a>
              </h3>
              {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
              {/* <h3>Vuejs/Reactjs/React Native</h3> */}
            </div>
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Broker pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Peer-to-peer pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Event-bus pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Model-view-controller pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Blackboard pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>

            <div>
              <div
                class="icon-box"
                style={{ marginTop: 20, marginRight: 10, padding: 10 }}
              >
                <h3>
                  <a href="/#">Interpreter pattern</a>
                </h3>
                {/* <img src={require("./assets/images/vue-react-reactnative.jpg")} class="ri-file-list-3-line" style={{width:350, height:100}}/> */}
                {/* <h3>Vuejs/Reactjs/React Native</h3> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
