import React, { Component } from 'react'
import { Tooltip } from 'react-tooltip'

export default class TechStackSection extends Component {
  render() {
    return (
      <section id="features">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
          >
            <h2>Our Tech Stacks</h2>
          </div>
          <div className="row clients techstack">
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/react.png')}
                className="img-fluid"
                alt="React"
                data-aos="zoom-in"
                data-aos-delay="500"
                data-tooltip-id="react-tooltip"
                data-tooltip-content="React"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/electron.png')}
                className="img-fluid"
                alt="Electron"
                data-aos="zoom-in"
                data-aos-delay="500"
                data-tooltip-id="electron-tooltip"
                data-tooltip-content="Electron"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/vue.png')}
                className="img-fluid"
                alt="Vue"
                data-aos="zoom-in"
                data-aos-delay="500"
                data-tooltip-id="vue-tooltip"
                data-tooltip-content="Vue"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/next.png')}
                className="img-fluid"
                alt="Next.js"
                data-aos="zoom-in"
                data-aos-delay="500"
                data-tooltip-id="next-tooltip"
                data-tooltip-content="Next.js"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/nodejs.png')}
                className="img-fluid"
                alt="Node.js"
                data-aos="zoom-in"
                data-tooltip-id="nodejs-tooltip"
                data-tooltip-content="Node.js"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/firebase.png')}
                className="img-fluid"
                alt="Firebase"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-tooltip-id="firebase-tooltip"
                data-tooltip-content="Firebase"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/mongodb.png')}
                className="img-fluid"
                alt="MongoDB"
                data-aos="zoom-in"
                data-aos-delay="200"
                data-tooltip-id="mongodb-tooltip"
                data-tooltip-content="MongoDB"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/mysql.png')}
                className="img-fluid"
                alt="MySQL"
                data-aos="zoom-in"
                data-aos-delay="200"
                data-tooltip-id="mysql-tooltip"
                data-tooltip-content="MySQL"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/python.png')}
                className="img-fluid"
                alt="Python"
                data-aos="zoom-in"
                data-aos-delay="300"
                data-tooltip-id="python-tooltip"
                data-tooltip-content="Python"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/django.png')}
                className="img-fluid"
                alt="Django"
                data-aos="zoom-in"
                data-aos-delay="300"
                data-tooltip-id="django-tooltip"
                data-tooltip-content="Django"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={require('./assets/images/flask.png')}
                className="img-fluid"
                alt="Flask"
                data-aos="zoom-in"
                data-aos-delay="300"
                data-tooltip-id="flask-tooltip"
                data-tooltip-content="Flask"
              />
            </div>
          </div>
          <Tooltip id="react-tooltip" />
          <Tooltip id="electron-tooltip" />
          <Tooltip id="vue-tooltip" />
          <Tooltip id="next-tooltip" />
          <Tooltip id="nodejs-tooltip" />
          <Tooltip id="firebase-tooltip" />
          <Tooltip id="mongodb-tooltip" />
          <Tooltip id="mysql-tooltip" />
          <Tooltip id="python-tooltip" />
          <Tooltip id="django-tooltip" />
          <Tooltip id="flask-tooltip" />
        </div>
      </section>
    )
  }
}
