import React, { Component } from 'react'
import { FaTimes } from 'react-icons/fa'

export default class WhatsAppModal extends Component {
  render() {
    return (
      <>
        {this.props.showWhatsAppModal && (
          <div className="whatsapp-modal">
            <div className="modal-header">
              <img
                src={require('./assets/images/service.png')}
                alt="staff"
              />
              <h4
                style={{
                  margin: 'auto',
                  marginLeft: '-5px',
                }}
              >
                Muhammad Umar
              </h4>
              <FaTimes
                style={{
                  marginLeft: 'auto',
                  cursor: 'pointer',
                  marginTop: '5px',
                  color: 'white',
                }}
                onClick={this.props.toggleWhatsAppModal}
              />
            </div>
            <div className="modal-body">
              <p>
                Hi, This is Muhammad Umar <br /> I am the customer relationship
                manager @IntelliglanceTech. <br /> I would be glad to help you.
              </p>
              <div className="modal-footer">
                <a href="https://api.whatsapp.com/send?phone=971568164532">
                  Reach out to me!
                </a>
              </div>
              <div className="modal-arrow" />
            </div>
          </div>
        )}
        <div
          style={{
            position: 'fixed',
            backgroundColor: '#25a71b',
            color: '#ffffff',
            padding: 10,
            borderRadius: 100,
            bottom: 70,
            right: 30,
            zIndex: 9999,
          }}
          className="Whatsapp_modal_icon"
          onClick={this.props.toggleWhatsAppModal}
        >
          <img
            style={{ width: 32, height: 32, cursor: 'pointer' }}
            src={require('./assets/images/whatsapp.png')}
            alt="whatsapp"
          />
        </div>
      </>
    )
  }
}
