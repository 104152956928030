import React, { Component } from 'react'
export default class FeaturesSection extends Component {
  render() {
    return (
      <section
        id="features"
        class="features"
      >
        <div class="container">
          <div
            class="section-title"
            data-aos="fade-up"
          >
            <h2>Features</h2>
            {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem</p> */}
          </div>

          <div
            class="row features"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div class="col-lg-3 col-md-4">
              <div class="icon-box">
                <i
                  class="ri-store-line"
                  style={{ color: '#ffbb2c' }}
                ></i>
                {/* <h3><a href="">Web Development</a></h3> */}
                <h3>Web Development</h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div class="icon-box">
                <i
                  class="ri-bar-chart-box-line"
                  style={{ color: '#5578ff' }}
                ></i>
                {/* <h3><a href="">Mobile App Development</a></h3> */}
                <h3>Mobile App Development</h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
              <div class="icon-box">
                <i
                  class="ri-calendar-todo-line"
                  style={{ color: '#e80368' }}
                ></i>
                {/* <h3><a href="">Graphic Designing</a></h3> */}
                <h3>Graphic Designing</h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4 mt-lg-0">
              <div class="icon-box">
                <i
                  class="ri-paint-brush-line"
                  style={{ color: '#e361ff' }}
                ></i>
                {/* <h3><a href="">Digital Marketing</a></h3> */}
                <h3>Digital Marketing</h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i
                  class="ri-database-2-line"
                  style={{ color: '#47aeff' }}
                ></i>
                {/* <h3><a href="">Database Management</a></h3> */}
                <h3>Database Management</h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i
                  class="ri-gradienter-line"
                  style={{ color: '#ffa76e' }}
                ></i>
                {/* <h3><a href="">Agile Methodolgy</a></h3> */}
                <h3>Agile Methodolgy</h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i
                  class="ri-file-list-3-line"
                  style={{ color: '#11dbcf' }}
                ></i>
                {/* <h3><a href="">Machine Learning</a></h3> */}
                <h3>Machine Learning</h3>
              </div>
            </div>
            {/* <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-price-tag-2-line" style={{color:'#4233ff'}}></i>
                <h3><a href="">Artificial Intelligence</a></h3>
              </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-anchor-line" style={{color:'#b2904f'}}></i>
                <h3><a href="">Dirada Pack</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-disc-line" style={{color:'#b20969'}}></i>
                <h3><a href="">Moton Ideal</a></h3>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-base-station-line" style={{color:'#ff5828'}}></i>
                <h3><a href="">Verdo Park</a></h3>
              </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-4 mt-4">
              <div class="icon-box">
                <i class="ri-fingerprint-line" style={{color:'#29cc61'}}></i>
                <h3><a href="">IOT based facilities</a></h3>
                <h3>IOT based facilities</h3>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    )
  }
}
