import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes as RouterRoutes,
  Route,
  useLocation,
} from 'react-router-dom'
import * as AppRoutes from './index'
import AppBar from '../../components/appbar'

const ScrollToHash = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  return null
}

const Navigation = () => {
  return (
    <Router>
      <ScrollToHash />
      <AppBar />
      <RouterRoutes>
        <Route
          exact
          path="/"
          element={<AppRoutes.HomePage />}
        />
        <Route
          exact
          path="/privacy_policy"
          element={<AppRoutes.PrivacyPolicyPage />}
        />
        <Route
          exact
          path="/terms_conditions"
          element={<AppRoutes.TermsConditionsPage />}
        />
      </RouterRoutes>
    </Router>
  )
}

export default Navigation
