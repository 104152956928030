// import React, { useEffect, useState } from 'react'
// import ProgressItems from './ProgressItems'
// import progressData from '../../data/progressData.json'

// const ProgressGrid = () => {
//   const [activeFilter, setActiveFilter] = useState('*')

//   useEffect(() => {
//     console.log(progressData)
//   }, [])

//   const handleFilterChange = (filter) => {
//     setActiveFilter(filter)
//   }

//   const filteredItems =
//     activeFilter === '*'
//       ? progressData.portfolioItems
//       : progressData.portfolioItems.filter(
//           (item) => item.category === activeFilter.replace('.', '')
//         )

//   return (
//     <div className="progress-container">
//       <div
//         className="section-title"
//         data-aos="fade-up"
//       >
//         <h2>Ongoing Projects</h2>
//       </div>
//       <div className="progress-filters">
//         {progressData.portfolioFilters.map((filter) => (
//           <button
//             key={filter.dataFilter}
//             className={
//               activeFilter === filter.dataFilter ? 'filter-active' : ''
//             }
//             onClick={() => handleFilterChange(filter.dataFilter)}
//           >
//             {filter.label}
//           </button>
//         ))}
//       </div>
//       <div className="progress-grid">
//         {filteredItems.map((item, index) => (
//           <ProgressItems
//             key={index}
//             item={item}
//           />
//         ))}
//       </div>
//     </div>
//   )
// }

// export default ProgressGrid

import React from 'react'

const ProgressGrid = () => {
  return <div></div>
}

export default ProgressGrid
