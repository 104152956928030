import React, { Component } from 'react'
import { Link } from 'react-scroll'
export default class AboutSection extends Component {
  render() {
    return (
      <section
        id="about"
        className="about"
      >
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
          >
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <p>
                Intelliglancetech has started providing solutions by its best
                entrepreneurs with vision to publicly mindful occupants of
                Pakistan about what innovation can truly bring to their
                organizations.
              </p>
              <ul>
                <li>
                  <i className="ri-check-double-line"></i>Our services are
                  engaged towards delivering results, not yields.
                </li>
                <li>
                  <i className="ri-check-double-line"></i>Our group is
                  enthusiastic and consistently puts system before execution.
                </li>
                <li>
                  <i className="ri-check-double-line"></i>It's our business, our
                  energy, our calling, and our aptitude.
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                Intelliglancetech has conveyed far reaching, trustworthy, and
                financially savvy IT re-appropriating answers for our customers
                driven by our guiding principle of achievement, Ownership, shown
                aptitude, attention to detail, cooperation, sound business
                judgment, and above all validity with our client.
              </p>
              {/* <a href="#" className="btn-learn-more">Learn More</a> */}
              <Link
                offset={300}
                to="services"
                name="services"
                spy={true}
                smooth={true}
                duration={1000}
                // className='nav-links'
                className="btn-learn-more"
                // onSetActive={this.active.bind(this)}
                // onClick={this.handleSetActive.bind(this)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
