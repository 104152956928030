import React, { Component } from 'react'
import ClientsSection from '../../components/clientsSection/index'
import AboutSection from '../../components/aboutSection/index'
import CountsSection from '../../components/countsSection/index'
import TechStackSection from '../../components/techstackSection/index'
import FeaturesSection from '../../components/featuresSection/index'
import FAQSection from '../../components/faqSection/index'
import ContactSection from '../../components/contactSection/index'
import Footer from '../../components/footer/index'
import AppBar from '../../components/appbar/index'
import { Element, Link } from 'react-scroll'
import ArchitechtureSection from '../../components/architechtureSection'
import PortfolioGrid from '../../components/portfolioSection/PortfolioGrid'
import ProgressGrid from '../../components/projectsInProgressSection/ProgressGrid'
import Certifications from '../../components/certifications/Certifications'
import WhatsAppModal from '../../components/WhatsAppModal/WhatsAppModal'

// import ServiceSection from '../../components/serviceSection/index'
// import TestimonialsSection from '../../components/testimonialSection/index'
// import PricingSection from '../../components/pricingSection/index'
// import TeamSection from '../../components/teamSection/index'
// import FounderSection from '../../components/founderSection/index'

export default class HomePage extends Component {
  constructor() {
    super()
    this.state = {
      showWhatsAppModal: false,
    }

    this.toggleWhatsAppModal = this.toggleWhatsAppModal.bind(this)
  }

  toggleWhatsAppModal() {
    this.setState((prevState) => ({
      showWhatsAppModal: !prevState.showWhatsAppModal,
    }))
  }
  render() {
    return (
      <div>
        <AppBar location="default" />
        <Element
          id="home"
          name="home"
        >
          <section
            id="hero"
            className="d-flex align-items-center"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                  <h1 data-aos="fade-up">
                    Grow your business with Intelliglancetech
                  </h1>
                  <h2
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    We are team of talanted designers powering platforms with
                    React/React-Native
                  </h2>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="800"
                  >
                    <Link
                      // offset={-600}
                      to="about"
                      name="about"
                      spy={true}
                      smooth={true}
                      duration={1000}
                      className="nav-links btn-get-started"
                      // onSetActive={this.active.bind(this)}
                      // onClick={this.handleSetActive.bind(this)}
                    >
                      Get Started
                    </Link>
                    {/* <a href="#about" className="btn-get-started scrollto">Get Started</a> */}
                  </div>
                </div>
                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  {/* <img src={require('./assets/images/Intelliglancetech-logo.png')} className="img-fluid animated" alt="" /> */}
                  <img
                    src={require('./assets/images/green-img-2-bgr.png')}
                    className="img-fluid animated"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </Element>

        <main id="main">
          <Element
            id="certifications"
            name="certifications"
          >
            <Certifications />
          </Element>
          <Element
            id="about"
            name="about"
          >
            <AboutSection />
            <CountsSection />
          </Element>

          {/* <Element id="founder" name="founder">
          </Element> */}

          {/* <Element
            id="team"
            name="team"
          >
            <FounderSection />
            <TeamSection />
          </Element> */}

          <Element
            id="clients"
            name="clients"
          >
            <ClientsSection />
          </Element>

          <Element
            id="features"
            name="features"
          >
            <FeaturesSection />
          </Element>

          {/* <ServiceSection /> */}

          <Element
            id="techstack"
            name="techstack"
          >
            <TechStackSection />
          </Element>

          <Element
            id="architechture"
            name="architechture"
          >
            <ArchitechtureSection />
          </Element>

          {/* <TestimonialsSection/> */}

          <Element
            id="ongoing"
            name="ongoing"
          >
            {/* <PortfolioSection /> */}
            <ProgressGrid />
          </Element>

          <Element
            id="portfolio"
            name="portfolio"
          >
            {/* <PortfolioSection /> */}
            <PortfolioGrid />
          </Element>

          {/* <Element id="pricing" name="pricing">
            <PricingSection />
          </Element> */}

          <Element
            id="faq"
            name="faq"
          >
            <FAQSection />
          </Element>

          <Element
            id="contact"
            name="contact"
          >
            <ContactSection />
          </Element>
        </main>
        <WhatsAppModal
          showWhatsAppModal={this.state.showWhatsAppModal}
          toggleWhatsAppModal={this.toggleWhatsAppModal}
        />
        <Footer />
      </div>
    )
  }
}
