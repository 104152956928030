import React, { useEffect, useState } from 'react'
import ProgressItems from './ProgressItem'
import progressData from '../../data/progressData.json'

const ProgressGrid = () => {
  const [activeFilter, setActiveFilter] = useState('*')

  useEffect(() => {
    console.log(progressData)
  }, [])

  const handleFilterChange = (filter) => {
    setActiveFilter(filter)
  }

  const filteredItems =
    activeFilter === '*'
      ? progressData.portfolioItems
      : progressData.portfolioItems.filter(
          (item) => item.category === activeFilter.replace('.', '')
        )

  return (
    <div>
      <div
        className="section-title"
        data-aos="fade-up"
      >
        <h2>Ongoing Projects</h2>
      </div>
      <div className="portfolio-filters">
        {progressData.portfolioFilters.map((filter) => (
          <div
            key={filter.dataFilter}
            className={
              activeFilter === filter.dataFilter ? 'filter-active' : ''
            }
            onClick={() => handleFilterChange(filter.dataFilter)}
          >
            {filter.label}
          </div>
        ))}
      </div>
      <div
        className="portfolio-grid"
        style={{ marginBottom: '30px' }}
      >
        {filteredItems.map((item, index) => (
          <ProgressItems
            key={index}
            item={item}
          />
        ))}
      </div>
    </div>
  )
}

export default ProgressGrid
