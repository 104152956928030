import React, { useEffect, useState, useRef } from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { MdChevronRight } from 'react-icons/md'
import './assets/css/appbar.css'

const AppBar = ({ location: externalLocation }) => {
  const [active, setActive] = useState('home')
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const contactRef = useRef(null)

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.slice(1)

      setTimeout(() => {
        const element =
          document.getElementById(elementId) ||
          (elementId === 'contact' && contactRef.current)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
          setActive(elementId)
        }
      }, 2000)
    }
  }, [location])

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen)
    document.body.classList.toggle('mobile-nav-active')
  }

  const handleNavItemClick = (name) => {
    setActive(name)
    setMobileNavOpen(false)
    document.body.classList.remove('mobile-nav-active')

    if (externalLocation === 'external') {
      navigate('/')
      setTimeout(() => {
        if (name !== 'home') {
          window.location.hash = name
        } else {
          window.history.replaceState(null, null, ' ')
        }
      }, 100)
    } else {
      if (name === 'home') {
        window.history.replaceState(null, null, ' ')
      } else {
        window.location.hash = name
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileNavOpen && !event.target.closest('.mobile-nav')) {
        setMobileNavOpen(false)
        document.body.classList.remove('mobile-nav-active')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [mobileNavOpen])

  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center navbar"
    >
      <div className="container d-flex align-items-center">
        <div className="logo mr-auto">
          <h1 className="text-light">
            <a href="https://www.intelliglancetech.com/">
              <div className="my-logo-div">
                <img
                  alt=""
                  className="my-logo-img"
                  src={require('./assets/images/Intelliglance.png')}
                />
              </div>
            </a>
          </h1>
        </div>
        <nav className={`nav-menu d-none d-lg-block`}>
          <ul>
            <li className={active === 'home' ? 'active' : ''}>
              <ScrollLink
                to="home"
                spy={true}
                smooth={true}
                duration={1000}
                offset={-90}
                onSetActive={() => setActive('home')}
                onClick={() => handleNavItemClick('home')}
              >
                Home
              </ScrollLink>
            </li>
            <li className={active === 'about' ? 'active' : ''}>
              <ScrollLink
                to="about"
                spy={true}
                smooth={true}
                duration={1000}
                offset={-90}
                onSetActive={() => setActive('about')}
                onClick={() => handleNavItemClick('about')}
              >
                About
              </ScrollLink>
            </li>
            <li className={active === 'ongoing' ? 'active' : ''}>
              <ScrollLink
                to="ongoing"
                spy={true}
                smooth={true}
                duration={1000}
                offset={-150}
                onSetActive={() => setActive('ongoing')}
                onClick={() => handleNavItemClick('ongoing')}
              >
                Ongoing Projects
              </ScrollLink>
            </li>
            <li className={active === 'portfolio' ? 'active' : ''}>
              <ScrollLink
                to="portfolio"
                spy={true}
                smooth={true}
                duration={1000}
                offset={-150}
                onSetActive={() => setActive('portfolio')}
                onClick={() => handleNavItemClick('portfolio')}
              >
                Portfolio
              </ScrollLink>
            </li>
            <li className={active === 'contact' ? 'active' : ''}>
              <ScrollLink
                to="contact"
                spy={true}
                smooth={true}
                duration={1000}
                offset={-150}
                onSetActive={() => setActive('contact')}
                onClick={() => handleNavItemClick('contact')}
              >
                Contact
              </ScrollLink>
            </li>
            <li className="get-started">
              <ScrollLink
                to="about"
                spy={true}
                smooth={true}
                duration={1000}
                offset={-90}
                onSetActive={() => setActive('about')}
                onClick={() => handleNavItemClick('about')}
              >
                Get Started
              </ScrollLink>
            </li>
          </ul>
        </nav>
        <button
          className="mobile-nav-toggle d-lg-none"
          onClick={toggleMobileNav}
        >
          {mobileNavOpen ? <></> : <FaBars />}
        </button>
      </div>
      <div className={`mobile-nav ${mobileNavOpen ? 'active' : ''}`}>
        <div className="mobile-logo">
          <img
            alt=""
            className="my-logo-img"
            src={require('./assets/images/Intelliglance.png')}
          />
        </div>
        <ul>
          <li
            className={active === 'home' ? 'active' : ''}
            style={{ paddingInline: '20px' }}
          >
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              duration={1000}
              offset={-90}
              onSetActive={() => setActive('home')}
              onClick={() => handleNavItemClick('home')}
            >
              <div className="containerMobileItem">
                Home
                <MdChevronRight
                  className="arrow"
                  color="gray"
                  style={{ fontSize: '28px' }}
                />
              </div>
            </ScrollLink>
          </li>
          <li
            className={active === 'about' ? 'active' : ''}
            style={{ paddingInline: '20px' }}
          >
            <ScrollLink
              to="about"
              spy={true}
              smooth={true}
              duration={1000}
              offset={-90}
              onSetActive={() => setActive('about')}
              onClick={() => handleNavItemClick('')}
            >
              <div className="containerMobileItem">
                About
                <MdChevronRight
                  className="arrow"
                  color="gray"
                  style={{ fontSize: '28px' }}
                />
              </div>
            </ScrollLink>
          </li>
          <li
            className={active === 'ongoing' ? 'active' : ''}
            style={{ paddingInline: '20px' }}
          >
            <ScrollLink
              to="ongoing"
              spy={true}
              smooth={true}
              duration={1000}
              offset={-70}
              onSetActive={() => setActive('ongoing')}
              onClick={() => handleNavItemClick('')}
            >
              <div className="containerMobileItem">
                Ongoing Projects
                <MdChevronRight
                  className="arrow"
                  color="gray"
                  style={{ fontSize: '28px' }}
                />
              </div>
            </ScrollLink>
          </li>
          <li
            className={active === 'portfolio' ? 'active' : ''}
            style={{ paddingInline: '20px' }}
          >
            <ScrollLink
              to="portfolio"
              spy={true}
              smooth={true}
              duration={1000}
              offset={-70}
              onSetActive={() => setActive('portfolio')}
              onClick={() => handleNavItemClick('')}
            >
              <div className="containerMobileItem">
                Portfolio
                <MdChevronRight
                  className="arrow"
                  color="gray"
                  style={{ fontSize: '28px' }}
                />
              </div>
            </ScrollLink>
          </li>
          <li
            className={active === 'contact' ? 'active' : ''}
            style={{ paddingInline: '20px' }}
          >
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              duration={1000}
              offset={-90}
              onSetActive={() => setActive('contact')}
              onClick={() => handleNavItemClick('')}
            >
              <div className="containerMobileItem">
                Contact
                <MdChevronRight
                  className="arrow"
                  color="gray"
                  style={{ fontSize: '28px' }}
                />
              </div>
            </ScrollLink>
          </li>
        </ul>
        <div
          style={{
            position: 'absolute',
            bottom: '20px',
          }}
        >
          <div
            style={{
              paddingInlineStart: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px', // reduce the gap between buttons
            }}
          >
            <button
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: '6px',
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                background: '#29a71a',
                color: 'white',
                fontSize: '15px',
                border: 'none',
                width: '200px',
              }}
              href="https://api.whatsapp.com/send?phone=971568164532"
            >
              Reach out on WhatsApp
            </button>
            <button
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: '6px',
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                background: '#29a71a',
                color: 'white',
                fontSize: '15px',
                border: 'none',
                width: '200px',
              }}
              href="https://calendly.com/intelliglancetech/30min"
            >
              Book a free consultation
            </button>
            <div
              style={{
                textAlign: 'center',
                fontSize: '15px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '20px',
              }}
            >
              IntelliglanceTech © 2024
            </div>
          </div>
        </div>
      </div>

      {mobileNavOpen && (
        <div
          className="mobile-nav-overly"
          onClick={toggleMobileNav}
        ></div>
      )}
    </header>
  )
}

export default AppBar
